#customPosition {
    position: absolute;
    top:0;
    background-color:'rgba(0,0,0,0)';
    z-Index:20;
    display: flex;
    width:100%;
    height:100%;
    justify-content:center;
    align-items:center;
}