#logo {
  width: 29.75%;
  height: 17.88%;
  margin-top: 9%;
  margin-bottom: 2%;
  min-width: 195px;
}
#logoRoles {
  width: 29.75%;
  height: 17.88%;
  margin-bottom: 2%;
  min-width: 195px;
}
