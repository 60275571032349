#main {
  /* width: 32%; */
  background-color: white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  border-radius: 15px;
  position: relative;
  height: 425px;
  padding-top: 5px;
}

#statsBar {
  flex: 1;
  background-color: red;
  height: 35px;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

#widgetTable td {
  font-size: x-small;
  font-weight: bold;
  text-align: end;
  vertical-align: middle;
  padding-top: 10px;
  padding-bottom: 10px;
  /* font-family: 'GothamBook'; */
}

button {
  background-color: transparent;
  border: none;
}

tbody {
  background-color: white;
}
