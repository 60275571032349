#log {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

#msgActualBody {
  height: 78vh;
  max-height: 78vh !important;
  min-height: 78vh !important;

  display: grid;
  grid-template-rows: auto 30px 100px;
  background-color: #fff;
}

#textBox {
  width: 100%;
  border: none;
  background-color: white;
  border-bottom: 1px solid #c1c1c1;
  outline: none;
}

::-webkit-input-placeholder {
  /* Edge */
  color: #c1c1c1;
  font-size: 14px;
  font-family: 'GothamBook', sans-serif;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c1c1c1;
  font-size: 14px;
  font-family: 'GothamBook', sans-serif;
}

::placeholder {
  color: #c1c1c1;
  font-size: 14px;
  font-family: 'GothamBook', sans-serif;
}

.cardMainMDPending {
  /* width: 85%; */
  margin: 2%;
  padding: 2% 5% 3% 5%;
  border-radius: 6px;
  color: black;
}

.cardBody {
  font-size: small !important;
  font-weight: 600;
  word-wrap: break-word;
}

.cardTimeStamp {
  margin-top: 5%;
  font-weight: 600;
  text-align: end;
}

#phraseItems {
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease-in-out;
  width: 350px;
  font-size: 12px;
  font-weight: bold;
}

#phraseItems:hover {
  background: whitesmoke;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

#phraseWrapper {
  display: flex;
  justify-content: space-between;
  border: 0.3px solid #d5cccc;
  border-radius: 6px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 2px;
}

#smartPhrasesDialog {
  position: absolute;
  z-index: 99999;
  top: 98px;
  background: whitesmoke;
  height: 50vh;
  right: 0;
  padding-top: 4px;
  box-shadow: 5px 5px #f4fcfd;
  overflow: auto;
  width: -webkit-fill-available;
  width: -moz-available;
}

.highlighter .highlighted {
  background-color: #ffeaa7;
  color: #e17055;
  box-shadow: -3px 0 0 #ffeaa7, 3px 0 0 #ffeaa7;
}
