.parentScroll {
  height: 200px;
  margin-top: 10px;
  overflow-y: scroll;
}
.ql-container {
  height: inherit;
}
.ql-toolbar {
  position: sticky;
  top: 0;
  background-color: white !important;
  z-index: 10;
}

.ql-editor {
  overflow: hidden;
}
.quilEditor img {
  width: 35%;
  height: 80px;
  object-fit: contain;
}
