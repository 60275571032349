#main {
  background-color: white;
  height: 100vh;
  margin-top: 10px;
  padding: 15px;
  overflow-y: scroll;
  margin-bottom: 15px;
  position: relative;
}

.customDialogSize {
  max-width: 58% !important;
}