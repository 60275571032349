#viewDataMain {
  width: 100%;
  position: relative;
}

#customViewDataHead {
  width: 100%;
  display: flex;
  background-color: transparent;
  align-items: flex-end;
}

#menuItem {
  text-align: center;
  color: #1699c5;
  font-size: 0.8vw;
  font-weight: bold;
  box-shadow: 0px 0px 10px 3px rgba(68, 68, 68, 0.1) !important;
  border-radius: 10px 10px 0 0;
  background-color: white;
  height: 80%;
  width: 25%;
}

#menuItem:hover {
  cursor: pointer;
  background-color: #1699c5;
  color: white;
}

#menuItemSelected {
  text-align: center;
  background-color: #1699c5;
  color: white;
  font-size: 1vw;
  font-weight: normal;
  box-shadow: 0px 0px 10px 3px rgba(68, 68, 68, 0.1) !important;
  border-radius: 10px 10px 0 0;
  height: 100%;
  width: 30%;
}

#viewSummaryData h5 {
  color: rgb(150, 150, 150);
  font-weight: 600;
  font-size: small;
}

#rangeSelector {
  border: 1px solid rgb(206, 206, 206);
  color: rgb(150, 148, 148);
}

#rangeSelector:focus {
  outline: none;
}

#patientViewData {
  box-shadow: 0 1px 10px 0 #0000001a, 0 10px 10px 0 rgba(68, 68, 68, 0.19) !important;
  border-radius: 0 10px 10px 10px;
  background-color: white;
  z-index: 20;
  position: relative;
  min-height: 450px;
}

#patientViewData::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 10px;
}

#patientViewData::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}

#patientViewData::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgb(224, 224, 224);
}

#collapseButton:focus {
  box-shadow: none;
}

#goButton:focus {
  box-shadow: none;
}

#summaryDataRowborder td {
  border-bottom: 1px solid rgb(231, 230, 230);
}

#chartjs-tooltip {
  line-height: normal;
  opacity: 1;
  padding: 0.3em;
  pointer-events: none;
  position: fixed;
  text-align: center;
  transform: translate(-50%, 0);
  transition: all 0.1s ease;
}
.customDialogSize {
  max-width: 58% !important;
}
