#newUserDropDown {
  width: 490px !important;
  border: none !important;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  padding: 10px 15px !important;

}

#newUserBtnContainer {
  /* position:fixed; */
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 3px;
  margin-bottom: 5px;
  column-gap: 14px;
}

#newUserBtnContainer>button {
  font-size: xx-small !important;
  font-family: "GothamBook", sans-serif;
  /* border-radius: 32px 32px 32px 32px; */
  width: 45%;
  /* padding: 10px 0px; */
  margin: 0px 0px 0px 1px;
}

#headText {
  font-size: xx-small !important;
  font-weight: 1000 !important;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0px 0px 5px 0px;
  top: 10px;
  position: relative;
}

#customTableHead {
  display: flex;
  width: 100%;
  background-color: white;
  color: grey;
  border-bottom: 1px solid rgb(209, 207, 207);
}

#customTableHead tr th {
  padding-left: 0px;
}

#customTableBody tr td {
  font-size: xx-small !important;
  color: grey;
  padding: 2% 0% 0% 0%;
}

.comboboxWidth {
  width: 50%;
  margin-left: 1%;
}

#topBarBtn {
  border: 1px solid transparent;
  background-color: transparent;
  padding: 5px;
  border-radius: 32px;
}

#topBarBtn:hover {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  background-color: white;
}

#topBarBtn:focus {
  outline: none;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  background-color: white;
}

#badge {
  position: absolute;
  /* top: -5px; */
  /* left: -5px; */
  font-size: x-small;
  padding: 1px 5px;
  border-radius: 50%;
  background: red;
  color: white;
}

#customTable::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 10px;
}

#customTable::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}

#customTable::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgb(224, 224, 224);
}

@media only screen and (max-width: 768px) {

  /* For medium devices: */
  #newUserDropDown {
    width: 300px !important;
    border: none !important;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
    padding: 10px 15px !important;
  }
}

#addNewUserModalHeader {
  border: none;
  padding-bottom: 0px;
}

#addNewUserModalTitle {
  color: black !important;
  font-size: small;
  font-weight: bold;
}

#addNewUserModalButton {
  width: 30%;
  border-radius: 32px 32px 32px 32px;
  font-size: small;
  padding: 1% 0%;
}

.sectionHeadingText {
  margin: 0;
  font-size: small !important;
  font-weight: 600 !important;
}

.errorCustom {
  border: 1px solid red !important;
}

.widthCustom {
  width: 32%;
  margin-left: 1%;
}

#selector {
  border: 1px solid rgba(221, 221, 221, 1);
  color: #838a8a;
  font-size: small;
  font-family: 'GothamBook', sans-serif;
  width: 100%;
  padding: 3.5% 2% 3.5% 1%;
}

#selectedSelector {
  border: 1px solid rgba(221, 221, 221, 1);
  color: black;
  font-family: 'GothamBook', sans-serif;
  font-size: small;
  width: 100%;
  padding: 3.5% 2% 3.5% 1%;
}

#selector:focus {
  outline: none;
  box-shadow: none;
}

#selectedSelector:focus {
  outline: none;
  box-shadow: none;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


#additionalRole {
  width: 65%;
}

#customLabel {
  margin: 0 5px !important;
  font-size: xx-small !important;
  font-weight: bold !important;
  color: black !important;
}

@media only screen and (max-width: 768px) {

  /* For medium devices: */
  #addNewUserModalButton {
    width: 70%;
    padding: 3% 0%;
  }

  .widthCustom {
    width: 100%;
    margin-left: 0;
    margin-top: 2%;
  }

  #additionalRole {
    width: 100%;
  }

}

input.loading {
  background: url('../../../../../../../assets/loading.gif') no-repeat right center;
}