.glowingCircle {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: white;
  -webkit-animation: glowing 0.5s ease-in-out infinite alternate;
  -moz-animation: glowing 0.5s ease-in-out infinite alternate;
  animation: glowing 0.5s ease-in-out infinite alternate;
}
@keyframes glowing {
  0% {
    box-shadow: 0px;
  }
  100% {
    box-shadow: 0 0 10px #2196f3, 0 0 20px #2196f3, 0 0 30px #2196f3, 0 0 40px #2196f3, 0 0 50px #2196f3, 0 0 60px #2196f3, 0 0 120px #2196f3;
  }
}
