.newUserDropDown {
  /* width: 275px !important; */
  border: none !important;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  padding: 10px 15px !important;
  width: 290px !important;
}
.horizentalRow {
  margin-top: 5px;
  margin-bottom: 0px;
}
.horizentalRowReadyToBill {
  margin-top: 1px;
  margin-bottom: 0px;
}
#newUserBtnContainer {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 1%;
  padding-top: 2%;
  border-top: 1px solid rgb(209, 207, 207);
}

#newUserBtnContainer > button {
  font-size: xx-small !important;
  font-family: 'GothamBook', sans-serif;
  border-radius: 32px 32px 32px 32px;
  width: 45%;
  padding: 10px 0px;
}

#headText {
  font-size: xx-small !important;
  font-weight: 1000 !important;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0px 0px 5px 0px;
}

#customTableHead {
  width: 100%;
  background-color: white;
  color: grey;
  border-bottom: 1px solid rgb(209, 207, 207);
}

#customTableHead tr th {
  padding-left: 0px;
}

#customTableBody tr td {
  font-size: xx-small !important;
  color: grey;
  padding: 2% 0% 0% 0%;
}
#customTableBodyNoData tr td {
  font-size: xx-small !important;
  color: grey;
  padding: 2% 0% 0% 0%;
}

#topBarBtn {
  border: 1px solid transparent;
  background-color: transparent;
  padding: 5px;
  border-radius: 32px;
}

#topBarBtn:hover {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  background-color: white;
}

#topBarBtn:focus {
  outline: none;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  background-color: white;
}

#badge {
  position: absolute;
  /* top: -5px; */
  /* left: -5px; */
  font-size: x-small;
  padding: 1px 5px;
  border-radius: 50%;
  background: red;
  color: white;
}

#customTable::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 10px;
}

#customTable::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}

#customTable::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgb(224, 224, 224);
}
/*   
  @media only screen and (max-width: 768px) {
    #newUserDropDown {
      width: 300px !important;
      border: none !important;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
      padding: 10px 15px !important;
    }
  } */
