.conversationListItem {
    display: flex;
    align-items: center;
    padding: 10px;
  }
  
  .conversationListItem:hover {
    background: #eeeef1;
    cursor: pointer;
  }
  
  .conversationPhoto {
    /* width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover; */
    margin-right: 10px;
    /* background-color: #00a400; */
    /* border: 1px solid #00a400; */
      /* border-color: #fff;
      border-radius: 10px;
      border-style: solid;
      border-width: 3;
      height: 12px;
      left: 50px;
      position: absolute;
      top: 40px;
      width: 12px; */
  }
  
  .conversationTitle {
    font-size: 14px;
    font-weight: bold;
    text-transform: capitalize;
    margin: 0;
  }
  
  .conversationSnippet {
    font-size: 14px;
    color: #888;
    margin: 0;
  }
  