#addNewUserModalHeader {
  border: none;
  padding-bottom: 0px;
}

#addNewUserModalTitle {
  color: black !important;
  font-size: small;
  font-weight: bold;
}
.reactCalendar {
  position: fixed !important;
}
#addNewUserModalButton {
  width: 30%;
  border-radius: 32px 32px 32px 32px;
  font-size: small;
  /* padding: 1% 0%; */
}

.sectionHeadingText {
  margin: 0;
  font-size: small !important;
  font-weight: 600 !important;
}

.errorCustom {
  border: 1px solid red !important;
}

.widthCustom {
  width: 32%;
  margin-left: 1%;
}

#selector {
  border: 1px solid rgba(221, 221, 221, 1);
  color: #838a8a;
  font-size: small;
  font-family: 'GothamBook',sans-serif;
  width: 100%;
  padding: 3.5% 2% 3.5% 1%;
}
.comboboxWidth {
  width: 50%;
  margin-left: 1%;
}

#selectedSelector {
  border: 1px solid rgba(221, 221, 221, 1);
  color: black;
  font-family: 'GothamBook',sans-serif;
  font-size: small;
  width: 100%;
  padding: 3.5% 2% 3.5% 1%;
}

#selector:focus {
  outline: none;
  box-shadow: none;
}

#selectedSelector:focus {
  outline: none;
  box-shadow: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

#additionalRole {
  width: 65%;
}

/* input.loading {
    background: url(http://www.xiconeditor.com/image/icons/loading.gif) no-repeat right center;
} */
input.loading {
  background: url('../../../../../../assets/loading.gif') no-repeat right center;
}

#customLabel {
  margin: 0 5px !important;
  font-size: xx-small !important;
  font-weight: bold !important;
  color: black !important;
}
.customizeDateInput fieldset {
  height: 36.13px;
  top: 0;
  border: 1px solid rgba(221, 221, 221, 1);
  border-radius: 0;
  outline: none;
}
.customizeDateInput fieldset legend {
  display: none;
}

@media only screen and (max-width: 768px) {
  /* For medium devices: */
  #addNewUserModalButton {
    width: 70%;
    padding: 3% 0%;
  }

  .widthCustom {
    width: 100%;
    margin-left: 0;
    margin-top: 2%;
  }

  #additionalRole {
    width: 100%;
  }
}

.datePickerCustomeStyle {
  outline: none !important;
  border-color: rgb(221, 221, 221);
  vertical-align: middle !important;
  display: flex !important;
  flex-grow: 1 !important;
  flex-shrink: 0 !important;
  height: 36px !important;
}
.datePickerCustomeStyle > .react-date-picker > .react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: 1px solid #c1c1c1 !important;
}
