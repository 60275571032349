.truncateWrapper {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.blinking {
  opacity: 1;
  animation: blinkingAnimation 1s infinite;
}
@keyframes blinkingAnimation {
  0% {
    opacity: 0.1;
  }
  25% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.8;
  }
  100% {
    opacity: 10;
  }
}
