#globalSearch {
  width: inherit;
  z-index: 1;
  position: absolute;
  margin-top: 2px;
  border-radius: 5px;
  max-height: 40vh;
  overflow-y: auto;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}
#globalSearch1 {
  width: 40rem;
  z-index: 25;
  position: absolute;
  margin-top: 3.5%;
  border-radius: 5px;
}
.widthCustom {
  width: 20%;
  margin-left: 1%;
}
#customLabel {
  margin: 0 5px !important;
  font-size: xx-small !important;
  font-weight: bold !important;
  color: black !important;
}

#main {
  display: flex;
  background-color: rgba(246, 252, 253, 1);
  width: 100%;
  height: 100%;
}
/*  
#container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 79%;
  margin-left: 243.5px;
  margin-left: 17%;
  margin-right: 2%;
  min-height: 100vh;
} */

#topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  gap: 10px;
}

#search {
  display: none;
  width: 55%;
  height: 55%;
  min-width: 270px;
}

#searchField {
  width: 100%;
  border-radius: 32px 0 0 32px;
  padding-left: 5%;
}
.searchResult {
  width: auto;
}
#searchButton {
  width: 21%;
  border-radius: 0px 32px 32px 0px;
  border: none;
  color: white;
  background-color: #1699c5;
  min-width: 73px;
}

#searchButton:focus {
  outline: none;
  box-shadow: none;
}

#topBarBtn {
  border: 1px solid transparent;
  background-color: transparent;
  padding: 5px;
  border-radius: 32px;
}

#topBarBtn:hover {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  background-color: white;
}

#topBarBtn:focus {
  outline: none;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  background-color: white;
}

#actionButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 40%; */
  margin-right: 2%;
  height: 65%;
}

#aBSubContainer {
  display: flex;
  /* width: 40%; */
  gap: 10px;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  /* min-width: 220px; */
  /* margin-left: 10%; */
}
#avator {
  padding-left: 10px;
}
/* #divider {
  width: 100%;
  margin: 0px;
} */

::-webkit-input-placeholder {
  /* Edge */
  color: rgba(119, 126, 131, 1);
  font-size: 12px;
  font-family: 'GothamBook',sans-serif;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(119, 126, 131, 1);
  font-size: 12px;
  font-family: 'GothamBook',sans-serif;
}

::placeholder {
  color: rgba(119, 126, 131, 1);
  font-size: 12px;
  font-family: 'GothamBook',sans-serif;
}

#mobileNav {
  display: none;
  height: 64px;
  width: 100%;
  justify-content: space-between;
  background-color: #1699c5;
  min-width: 360px;
}

#mobileNav > h4 {
  margin: 0px;
  display: flex;
  align-items: center;
  color: white;
  font-size: large;
  font-family: 'GothamBook',sans-serif;
  margin-left: 5%;
  font-weight: bold;
}

#mobActionBar {
  display: flex;
  align-items: center;
  margin-right: 5%;
}

@media only screen and (max-width: 768px) {
  /* For medium devices: */

  #main {
    flex-direction: column;
  }

  #container {
    width: 92%;
    margin-left: 4%;
    margin-right: 2%;
  }

  #mobileNav {
    position: relative;
    width: 100%;
  }
 #actionButton {
    width: 100%;
    justify-content: center;
  }
  #search {
    width: 90%;
    height: 55%;
  }

  #avator {
    height: auto;
  }
}

