#HelpDropDown {
  border: none !important;
  box-shadow: 0 2px 6px 0 #0000001a, 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  /* padding: 0 25%; */
  width: 225px;
  right: -40px;
  position: absolute;
  z-index: 5;
}

.menuArrow {
  top: -18px;
  left: 52%;
  width: 0;
  height: 0;
  position: relative;
}

#badge {
  position: absolute;
  top: 5px;
  left: 4px;
  /* font-size: x-small;
  padding: 1px 5px;
  border-radius: 50%; */
  /* background: red; */
  color: red;
}

.menuArrow:before,
.menuArrow:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-width: 7px 8px;
  border-style: solid;
  border-color: transparent;
  z-index: 1001;
}

.menuArrow:after {
  bottom: -18px;
  right: -8px;
  border-bottom-color: #fff;
}

.menuArrow:before {
  bottom: -17px;
  right: -8px;
  border-bottom-color: rgba(0, 0, 0, 0.15);
}

#row:hover {
  cursor: pointer;
}

#topBarBtnSmall {
  border: 1px solid transparent;
  background-color: transparent;
  padding: 10px;
  border-radius: 32px;
}

#topBarBtnSmall:hover {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  background-color: white;
}

#topBarBtnSmall:focus {
  outline: none;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  background-color: white;
}
