.transition {
  animation-name: scaling;
  animation-duration: 0.5s;
}
@keyframes scaling {
  0% {
    transform: scale(1, 0);
  }

  100% {
    transform: scale(1, 1);
  }
}
