.messenger {
  display: flex;
  width: 100%;
  /* margin-left: -5%; */
  /* background-color:rgb(231, 229, 229); */
  height: 88vh;
}
.viewMessenger {
  display: flex;
  width: 100%;
}
.sidebar {
  width: 30%;
  background-color: white;
  border-right: 5px solid rgb(241, 241, 241);
  height: 88vh;
}
.viewSideBar {
  height: 45vh;
}
.ViewContent {
  width: 70%;
  height: 51%;
}

.content {
  width: 70%;
  height: 100%;
}

#puff {
  left: 0;
  position: fixed !important;
  top: 0 !important;
  background-color: 'rgba(0,0,0,0)' !important;
  z-index: 20 !important;
  display: flex !important;
  width: 100% !important;
  height: 100% !important;
  justify-content: center !important;
  align-items: center !important;
}
