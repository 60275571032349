.message {
  display: flex;
  flex-direction: column;
}

.message .timestamp {
  display: flex;
  justify-content: flex-end;
  /* color: #999; */
  font-weight: 600;
  font-size: 9px;
  margin-top: 8px;

  text-transform: uppercase;
}

.userMessage:hover{
  text-decoration: underline;
}

.message .bubbleContainer {
  font-size: 14px;
  display: flex;
}

.message.mine .bubbleContainer {
  justify-content: flex-end;
}

.message.start .bubbleContainer .bubble {
  margin-top: 10px;
  border-top-left-radius: 20px;
}

.message.end .bubbleContainer .bubble {
  border-bottom-left-radius: 20px;
  margin-bottom: 10px;
}

.message.mine.start .bubbleContainer .bubble {
  margin-top: 10px;
  border-top-right-radius: 20px;
}

.message.mine.end .bubbleContainer .bubble {
  border-bottom-right-radius: 20px;
  margin-bottom: 10px;
}


.message .bubbleContainer .bubble {
  margin: 1px 0px;
  background: #f4f4f8;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 75%;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.message.mine .bubbleContainer .bubble {
  background: #007aff;
  color: white;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.message.mine .bubbleContainer .bubble a{
  color: white;
  font-size: 14px;
  font-style: italic;
}

