.faqsComponent {
    /* background-color:red */
}

/* 
p{
    font-size:14px;
} */

.faqComponent {

    margin-top: 12px;
}

.question {
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 12px;
    /* border-left: 4px solid blue; */
    /* margin:10px; */
    align-items: center;
    border-radius: 7px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    /* font-size:12px; */
}

.question:hover {
    cursor: pointer;
    box-shadow: rgb(4, 97, 197) 0px 0px 3px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    transition: .5s;
}

.iconBox {
    display: flex;
    justify-content: flex-end;
}


.answer {
    padding: 20px;
    border-radius: 7px;
    margin-top: 5px;
    margin-bottom: -5px;
    margin-left: 15px;
    margin-right: 15px;
    font-size: medium;
    text-align: justify;
    font-family: serif;
    box-shadow: rgb(28 0 241 / 36%) 0px 0px 3px 0px, rgb(122 153 230 / 82%) 0px 0px 1px 0px;
    max-height: 150px;
    overflow-y: scroll;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    /* font-size:12px; */
    /* border-left: 4px solid grey; */
}



.linkText {
    font-size: 14px;
}

.answer p {
    font-size: 14px;
}

.answer ol {
    font-size: 14px;
}

.answer ol li {
    font-size: 14px;
}

.answer ul li {
    font-size: 14px;
}