#log {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#msgActualBody {
  height: 66vh;
  max-height: 66vh !important;
  min-height: 66vh !important;
}
#textBox {
  width: 100%;
  border: none;
  background-color: white;
  border-bottom: 1px solid #c1c1c1;
  outline: none;
}

::-webkit-input-placeholder {
  /* Edge */
  color: #c1c1c1;
  font-size: 14px;
  font-family: 'GothamBook',sans-serif;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c1c1c1;
  font-size: 14px;
  font-family: 'GothamBook',sans-serif;
}

::placeholder {
  color: #c1c1c1;
  font-size: 14px;
  font-family: 'GothamBook',sans-serif;
}
.cardMainMDPending {
  width: 85%;
  margin: 2%;
  padding: 2% 5% 3% 5%;
  border-radius: 6px;
  color: black;
}

.cardBody {
  font-size: small !important;
  font-weight: 600;
  word-wrap: break-word;
}

.cardTimeStamp {
  margin-top: 2%;
  font-weight: 600;
  text-align: end;
}
