/* Style buttons */
.customFilterButton {
  background-color: #1699C5;
  /* Blue background */
  border: none;
  /* Remove borders */
  color: white;
  /* White text */
  padding: 6px 16px;
  /* Some padding */
  font-size: 16px;
  /* Set a font size */
  cursor: pointer;
  /* Mouse pointer on hover */
  margin: 0 30px 10px 0;

}

/* Darker background on mouse-over */
.customFilterButton:hover {
  background-color: dodgerblue;
}

.customFilterButton:focus {
  outline: none;
}

/* Style buttons */
.customApplyButton {
  background-color: green;
  /* Blue background */
  border: none;
  /* Remove borders */
  color: white;
  /* White text */
  padding: 6px 16px;
  /* Some padding */
  font-size: 16px;
  /* Set a font size */
  cursor: pointer;
  /* Mouse pointer on hover */
  margin: 0 10px 10px 0;
}

/* Darker background on mouse-over */
.customApplyButton:hover {
  background-color: #1fbd17;
}

.customApplyButton:focus {
  outline: none;
}

/* Style buttons */
.customResetButton {
  background-color: #c41717;
  /* Blue background */
  border: none;
  /* Remove borders */
  color: white;
  /* White text */
  padding: 6px 16px;
  /* Some padding */
  font-size: 16px;
  /* Set a font size */
  cursor: pointer;
  /* Mouse pointer on hover */
  margin: 0 10px 10px 0;
}

/* Darker background on mouse-over */
.customResetButton:hover {
  background-color: rgb(236, 1, 1);
}

.widthCustom {
  width: 30%;
  margin-left: 2%;
}

#practiceState {
  border: 1px solid rgba(221, 221, 221, 1);
  color: #838a8a;
  font-size: small;
  font-family: 'GothamBook', sans-serif;
  width: 100%;
  padding: 3.5% 2% 3.5% 1%;
}

#selectedPracticeState {
  border: 1px solid rgba(221, 221, 221, 1);
  color: black;
  font-family: 'GothamBook', sans-serif;
  font-size: small;
  width: 100%;
  padding: 3.5% 2% 3.5% 1%;
}

#practiceState:focus {
  outline: none;
  box-shadow: none;
}

#selectedPracticeState:focus {
  outline: none;
  box-shadow: none;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.customResetButton:focus {
  outline: none;
}

::-webkit-calendar-picker-indicator:hover {
  cursor: pointer;
}