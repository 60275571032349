#addNewUserModalHeader {
  border: none;
  padding-bottom: 0px;
}

.myDatePickerStyle {
  display: flex !important;
  height: 35.78px;
}

#addNewUserModalTitle {
  color: black !important;
  font-size: small;
  font-weight: bold;
}

#addNewUserModalButton {
  width: 30%;
  border-radius: 32px 32px 32px 32px;
  font-size: small;
  padding: 1% 0%;
}

.sectionHeadingText {
  margin: 0;
  font-size: small !important;
  font-weight: 600 !important;
}

.errorCustom {
  border: 1px solid red !important;
}

.widthCustom {
  width: 32%;
  margin-left: 1%;
}

.extension {
  width: 10%;
  margin-left: 1%;
}

#selector {
  border: 1px solid rgba(221, 221, 221, 1);
  color: #838a8a;
  font-size: small;
  font-family: 'GothamBook',sans-serif;
  width: 100%;
  padding: 3.5% 2% 3.5% 1%;
}

#selectedSelector {
  border: 1px solid rgba(221, 221, 221, 1);
  color: black;
  font-family: 'GothamBook',sans-serif;
  font-size: small;
  width: 100%;
  padding: 3.5% 2% 3.5% 1%;
}

#selector:focus {
  outline: none;
  box-shadow: none;
}

#selectedSelector:focus {
  outline: none;
  box-shadow: none;
}

#customLabel {
  margin: 0 5px !important;
  font-size: xx-small !important;
  font-weight: bold !important;
  color: black !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

#additionalRole {
  width: 65%;
}

label {
  margin: 0 2% !important;
  font-size: small;
}

@media only screen and (max-width: 768px) {

  /* For medium devices: */
  #addNewUserModalButton {
    width: 70%;
    padding: 3% 0%;
  }

  .widthCustom {
    width: 100%;
    margin-left: 0;
    margin-top: 2%;
  }

  #additionalRole {
    width: 100%;
  }
}

.react-date-picker {
  display: inline-flex;
  position: relative;
}

.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-date-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}

.reactDatePickerWrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;

}

.react-date-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
}

.emailInput {
  margin-left: 0px;
  padding: 2%;
  display: block;

}

.react-date-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}

.react-date-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
}

.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-date-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}

.react-date-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}

.react-date-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}

.react-date-picker__button:enabled {
  cursor: pointer;
}

.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: #0078d7;
}

.react-date-picker__button:disabled .react-date-picker__button__icon {
  stroke: #6d6d6d;
}

.react-date-picker__button svg {
  display: inherit;
}

.react-date-picker__calendar {
  width: 350px;
  max-width: 100vw;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}
.customDialogSize {
  max-width: 40% !important;
}

@media only screen and (max-width: 1800px) {
  .emailInput {
    margin-left: -8.5px;
    padding: 2%;
    display: block;

  }


}

@media only screen and (max-width: 768px) {
  .extension {
    width: 20%;
    margin-left: 0;
  }

  .emailInput {
    margin-left: 0px;
    padding: 2%;
    display: block;

  }


}