.rolesHeader {
  font-weight: 400;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: large;
}
.avatar .avatarImage {
  border: 4px solid #2fa3ff4f;
  background-color: #44b7ff82;
}
.avatar {
  display: flex;
  justify-content: center;
}
.practiceImage {
  width: 100%;
  height: 120px;
  object-fit: cover;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: 1px solid #0d6ab45e;
}
.practiceName p {
  text-align: start;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  color: rgba(0, 0, 0, 0.6);
  font-size: medium;
  max-width: 98%;
  font-weight: 600;
}

.location {
  font-weight: 600;
  text-align: start;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  color: rgba(0, 0, 0, 0.6);
  font-size: medium;
  max-width: 80%;
  line-height: 17px;
}
.actionButtons {
  width: 100%;
  text-align: end;
}
.roleInfoContainer {
  padding: 10px 0px;
}
