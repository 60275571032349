.pending {
  color: #007aff;
  animation-name: pendingState;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  opacity: 0.9;
}

@keyframes pendingState {
  0% {
    opacity: 0.4;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 0.9;
  }
}

/* error state */
.error,
.realTimeSentError {
  background-color: red !important;
  color: white !important;
}

.errorTimeStamp {
  color: white !important;
}
#fullModalImage {
  width: 100%;
}
#fullModalImageContainer {
  display: flex;
}

#inBoundmmsVideoContainer,
#inboundAppFile,
#inboubdMmsAud,
#inboubdMmsImageContainer {
  margin: 1% 0% 1% 0%;
  border-radius: 5px;
  padding: 5px;
  background-color: #007aff;
  margin-left: auto !important;
}
/* common styling for outBoundMms */

#outBoundAppFile,
#outBoundMmsvidContainer,
#outBoubdMmsAud,
#outBoubdMmsImageContainer {
  margin: 1% 0% 1% 0%;
  color: black !important;
  padding: 5px;
  margin-right: auto !important;
  border-radius: 5px;
}

#inboundAppFile,
#outBoundAppFile {
  width: 30%;
}
#inBoundmmsVideoContainer {
  width: 50%;
}
#inboubdMmsVid {
  width: 100%;
  display: flex;
  justify-content: end;
}
#inboubdMmsVid video {
  width: inherit;
}
#outBoundMmsvidContainer {
  width: 50%;
}
#outBoundMmsVid {
  width: 100%;
  display: flex;
  justify-content: end;
}
#outBoundMmsVid video {
  width: inherit;
}

.msgLink {
  text-decoration: none;
  color: black;
}

#inboubdMmsAud,
#outBoubdMmsAud {
  width: 34%;
}
#inboubdMmsAud audio,
#outBoubdMmsAud audio {
  width: 100%;
  height: 25px;
}

#inboubdMmsImageContainer,
#outBoubdMmsImageContainer {
  width: 34%;
}

#outBoubdMmsImage,
#inboubdMmsImage {
  width: 100%;
  display: flex;
}

#outBoubdMmsImage img,
#inboubdMmsImage img {
  cursor: pointer;
  height: 250px !important;
  width: 100%;
  font-weight: 500;
}

#outBoundCardTimeStamp,
#inBoundCardTimeStamp {
  font-size: 9px !important;
  margin-top: 8px;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 20px !important;
}
#inBoundCardTimeStamp {
  color: white;
}
#outBoundCardTimeStamp {
  color: black;
}

@media only screen and (max-width: 2200px) {
  #inboubdMmsImageContainer,
  #outBoubdMmsImageContainer {
    width: 34%;
  }
}

@media only screen and (max-width: 1800px) {
  #inboubdMmsImageContainer,
  #outBoubdMmsImageContainer {
    width: 34%;
  }
}

@media only screen and (max-width: 1560px) {
  #inboubdMmsImageContainer,
  #outBoubdMmsImageContainer {
    width: 40%;
  }
}

@media only screen and (max-width: 1350px) {
  #inboubdMmsImageContainer,
  #outBoubdMmsImageContainer {
    width: 45%;
  }
}

@media only screen and (max-width: 1200px) {
  #inboubdMmsImageContainer,
  #outBoubdMmsImageContainer {
    width: 50%;
  }
}

@media only screen and (max-width: 992px) {
  #inboubdMmsImageContainer,
  #outBoubdMmsImageContainer {
    width: 60%;
  }
}

@media only screen and (max-width: 768px) {
  #inboubdMmsImageContainer,
  #outBoubdMmsImageContainer {
    width: 50%;
  }
}
