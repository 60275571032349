.customHeader {
  margin-top: 2px;
  color: white;
  margin-left: 10px;
  font-size: medium;
}
/* .truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 95%;
  font-size: small;
  display: block;
} */
.truncates {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 102%;
  font-size: 16px;
  text-align: left;
  display: block;
  margin-left: -14px;
  font-weight: 500;
}
.mainGridContainer {
  display: grid;
  grid-template-columns: 190px 1fr;
  grid-template-rows: minmax(67px, auto) auto;
}

@media screen and (max-width: 900px) {
  .mainGridContainer {
    grid-template-columns: auto;
  }
}
