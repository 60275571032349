#practiceContainer {
    width: 100%;
    background-color: white;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08), 0 2px 10px 0 rgba(0, 0, 0, 0.08);
    border-radius: 10px;
  }
  
  #headTextContainer {
    padding: 3% 4%;
  }
  
  #headText {
    font-weight: 550;
    color: black;
    margin: 0;
  }
  #headMediumSizeText {
    font-weight: 250;
    color: black;
    margin: 0;
  }
  
  #headButtonContainer {
    width: 70%;
    text-align: end;
  }
  
  #headButton {
    font-size: xx-small;
    border-radius: 32px 32px 32px 32px;
    padding: 0.7% 3%;
    margin: 1%;
    min-width: 100px;
  }
  
  #body tr td {
    color: grey;
    font-weight: 600;
  }
  
  .customCol0 {
    width: 2%;
  }
  
  .customCol1 {
    width: 18%;
    min-width: 120px;
    cursor: pointer;
  }
  
  .customCol2 {
    width: 18%;
    cursor: pointer;
    min-width: 120px;
  }
  
  .customCol3 {
    width: 10%;
    cursor: pointer;
    min-width: 75px;
  }
  
  .customCol4 {
    width: 10%;
    cursor: pointer;
    min-width: 80px;
  }
  
  .customCol5 {
    width: 10%;
    cursor: pointer;
    min-width: 95px;
  }
  
  .customCol6 {
    width: 10%;
    cursor: pointer;
    min-width: 85px;
  }
  
  .customCol7 {
    width: 10%;
    min-width: 85px;
  }
  
  .customCol8 {
    width: 12%;
    min-width: 105px;
  }
  
  .customCol9 {
    width: 10%;
    min-width: 65px;
  }
  
  #editButton {
    font-size: xx-small;
    border-radius: 32px 32px 32px 32px;
    padding: 2% 16%;
    /* width: 90%;
    min-width: 40px; */
  }
  #inactiveButton {
    font-size: xx-small;
    border-radius: 32px 32px 32px 32px;
    padding: 4% 16%;
    /* width: 90%; */
    min-width: 40px;
  }
  
  #rowsPerPage {
    border: none;
    font-weight: 600;
  }
  
  #rowsPerPage:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  #rowsPerPage:focus {
    outline: none;
  }
  
  #customTableFooterText {
    font-size: small !important;
    color: black;
    margin: 0% 1% !important;
  }
  
  #customPageinationButton {
    border: none;
    color: black;
    padding: 12px 16px;
    font-size: large;
    border-radius: 50%;
  }
  
  #customPageinationButton:hover {
    background-color: rgba(0, 0, 0, 0.1);
    /* box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important; */
  }
  
  #customPageinationButton:focus {
    outline: none;
  }
  
  #customPageinationButtonDisbaled {
    border: none;
    color: rgb(190, 190, 190);
    padding: 12px 16px;
    font-size: large;
    border-radius: 50%;
  }
  
  #customPageinationButtonDisabled:focus {
    outline: none;
  }
  
  #customPageinationButton1 {
    border: none;
    color: black;
    padding: 12px 18px;
    font-size: large;
    border-radius: 50%;
  }
  
  #customPageinationButton1:hover {
    background-color: rgba(0, 0, 0, 0.1);
    /* box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important; */
  }
  
  #customPageinationButton1:focus {
    outline: none;
  }
  
  #customPageinationButton1Disabled {
    border: none;
    color: rgb(190, 190, 190);
    padding: 12px 18px;
    font-size: large;
    border-radius: 50%;
  }
  
  #customPageinationButton1Disabled:focus {
    outline: none;
  }
  
  @media only screen and (max-width: 768px) {
    /* For medium devices: */
    .customCol1 {
      min-width: 105px;
    }
  
    .customCol2 {
      min-width: 105px;
    }
  
    .customCol3 {
      min-width: 105px;
    }
  
    .customCol4 {
      min-width: 105px;
    }
  
    .customCol5 {
      min-width: 105px;
    }
  
    .customCol6 {
      min-width: 105px;
    }
  
    .customCol7 {
      min-width: 105px;
    }
  
    .customCol8 {
      min-width: 105px;
    }
  
    .customCol9 {
      min-width: 105px;
    }
  }
  