.animatedIcon {
  animation: animateIcon 1s linear infinite;
}
@keyframes animateIcon {
  0% {
    color: white;
  }
  50% {
    color: rgba(0, 0, 0, 0.38);
  }

  100% {
    color: #1699c5;
  }
}

.input {
  width: 100%;
  height: 40px;
  border: 1px solid rgb(184, 184, 184);
}
.practiceLogoLabel {
  color: #8e8e8e;
  font-weight: 550;
  font-family: system-ui;
  display: inline-block;
}
.logoLabelEror {
  color: red;
  display: inline-block;
}
.practiceLogo {
  width: inherit;
  border-radius: inherit;
  height: inherit;
  object-fit: fill;
}
.fileError {
  color: red;
}
.animateInput {
  animation: animateInput 1s linear infinite;
}

@keyframes animateInput {
  0% {
    color: red;
  }

  50% {
    color: blue;
  }

  75% {
    color: green;
  }

  100% {
    color: yellow;
  }
}

.input:hover {
  border: 1px solid black;
}
.roleLabel {
  margin-left: 10px !important;
}
.radio {
  transform: scale(1.5);
}

.radio:hover {
  cursor: pointer;
}

.error {
  width: 100%;
  height: 40px;
  border: 1px solid red;
}

.errorPara {
  color: red;
  font-size: x-small;
}

.withoutBorder fieldset {
  border-radius: 0px;
}

#addNewPracticeModalHeader {
  border: none;
  padding-bottom: 0px;
}

#addNewPracticeModalTitle {
  color: black !important;
  font-size: small;
  font-weight: bold;
}

#addNewPracticeModalButton {
  width: 30%;
  border-radius: 32px 32px 32px 32px;
  font-size: small;
  padding: 1% 0%;
}

.Addpractice fieldset {
  border-radius: 0;
  width: 50px;
}

#btnNext {
  border-radius: 25px;
  width: 150px;
  margin-left: 20px;
  margin-top: 15px;
}

#addProviderBtn {
  border-radius: 25px;
  width: 150px;
  /* margin-left: 20px;
    margin-top: 15px; */
}

.sectionHeadingText {
  margin: 0;
  font-size: small !important;
  font-weight: 600 !important;
}

.errorCustom {
  border: 1px solid red !important;
}

.widthCustom {
  width: 32%;
  margin-left: 1%;
}

#selector {
  border: 1px solid rgba(221, 221, 221, 1);
  color: #b6b6b6;
  font-size: small;
  font-family: 'GothamBook', sans-serif;
  width: 100%;
  padding: 3.5% 2% 3.5% 1%;
}

#selectedSelector {
  border: 1px solid rgba(221, 221, 221, 1);
  color: black;
  font-family: 'GothamBook', sans-serif;
  font-size: small;
  width: 100%;
  padding: 3.5% 2% 3.5% 1%;
}

#selector:focus {
  outline: none;
  box-shadow: none;
}

#selectedSelector:focus {
  outline: none;
  box-shadow: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

#practiceAddress,
#practiceAddressLine1,
#practiceAddressLine2 {
  width: 98%;
  padding: 1% 1% 1% 0.5%;
}

textarea:focus {
  outline: none;
}

#addNewProviderButton {
  margin-right: 2%;
  width: 20%;
  border-radius: 32px 32px 32px 32px;
  font-size: small;
  padding: 1% 0%;
}

#additionalRole {
  width: 65%;
}

label {
  margin: 0 2% !important;
  font-size: small;
}

@media only screen and (max-width: 768px) {
  /* For medium devices: */
  #addNewPracticeModalButton {
    width: 70%;
    padding: 3% 0%;
  }

  #addNewProviderButton {
    width: 50%;
    padding: 3% 0%;
  }

  .widthCustom {
    width: 100%;
    margin-left: 0;
    margin-top: 2%;
  }

  #additionalRole {
    width: 100%;
  }

  #practiceAddress,
  #practiceAddressLine1,
  #practiceAddressLine2 {
    width: 100%;
    padding: 2%;
  }
}
