#eFaxContainer {
  width: 100%;
  margin-top: 3%;
  background-color: white;
  box-shadow:
    0 2px 10px 0 rgba(0, 0, 0, 0.08),
    0 2px 10px 0 rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}

#headTextContainer {
  padding-top: 5%;
  padding-left: 5%;
  padding-right: 5%;
}

#headText {
  font-weight: 550;
  color: black;
  margin: 0;
}
#CardText {
  font-weight: 450;
  color: black;
  background-color: aliceblue;
  text-align: center;
  border-radius: 5px;
  margin: 0;
}
#headButton {
  font-size: small;
  border-radius: 32px 32px 32px 32px;
  padding: 1.5% 2% 2% 2%;
  width: 20%;
  margin: 0 5px;
  min-width: 100px;
}

.badge {
  display: inline-block;
  padding: 5px, 10px;
  background-color: #6ee4b6;
  border-radius: 15px;
  color: white;
  border-radius: 4px;
  font-weight: 400;
}

#body tr td {
  color: grey;
  font-weight: 600;
}

.customCol0 {
  width: 1%;
}

.customCol1 {
  width: 13%;
  min-width: 100px;
  cursor: pointer;
}

.customCol2 {
  width: 13%;
  cursor: pointer;
  min-width: 100px;
}

.customCol3 {
  width: 13%;
  cursor: pointer;
  min-width: 100px;
  line-height: 1em;
}

.customCol4 {
  width: 13%;
  cursor: pointer;
  min-width: 100px;
  line-height: 1em;
}

.customCol5 {
  width: 13%;
  cursor: pointer;
  min-width: 71px;
}

.customCol6 {
  width: 10%;
  cursor: pointer;
  min-width: 45px;
  line-height: 1em;
}

.customCol7 {
  width: 10%;
  min-width: 45px;
}

#editButton {
  font-size: xx-small;
  border-radius: 32px 32px 32px 32px;
  padding: 2% 16%;
  width: 90%;
  min-width: 40px;
}

@media only screen and (max-width: 768px) {
  /* For medium devices: */
  #eFaxContainer {
    width: 100%;
  }
}
