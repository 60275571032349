
#detailContainer {
  height: 88vh;
  margin: 0% 0% 0% 1.5%;
  padding: 0% 1.5% 0% 0.5%;
  overflow: auto;
}

#detailContainer::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 10px;
}

#detailContainer::-webkit-scrollbar {
  width: 2px;
  background-color: #f5f5f5;
}

#detailContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #dddddd;
}
