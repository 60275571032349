#pastDataMain {
    width: 100%;
    position: relative;
    background-color: white;
    box-shadow: 0 1px 10px 0 #0000001a, 0 10px 10px 0 rgba(68, 68, 68, 0.19) !important;
    border-radius: 10px;
}

#pastDataMain::-webkit-scrollbar-track
{
	background-color: #F5F5F5;
	border-radius: 10px;
}

#pastDataMain::-webkit-scrollbar
{
	width: 4px;
	background-color: #F5F5F5;
}

#pastDataMain::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    background-color: rgb(224, 224, 224);
}

#collapseButton:focus {
    box-shadow: none;
}