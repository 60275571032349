@font-face {
  font-family: 'GothamBook';
  src: local('GothamBook'), url(./assets/fonts/GothamBook.otf) format('opentype');
}

html,
#app,
#app>div {
  height: 100% !important;
}

body {
  height: 100% !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Custom properties to override material ui components */
.sidebarContainer {
  grid-row-start: 1;
  grid-row-end: 3;
}

.sidebarContainer .MuiDrawer-docked {
  height: 100%;
}

.mainSidebar>div {
  position: sticky !important;
  height: 100vh !important;
}

@media screen and (max-width: 900px) {
  #wrapper_topbar__3n2bZ .searchContainer {
    position: absolute;
    top: 60px;
    left: 0;
  }
}

/* Swal /Sweet alert2 Css hiding z-index showing on modal */
.swal2-container {
  z-index: 99999 !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23) !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23) !important;
}