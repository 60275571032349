#telemedicineDialer {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  background-color: white;
  width: 100%;
  position: relative;
  padding-left: 0.7%;
  /* border: "1 px solid #000"; */
}

/* enable absolute positioning */
.rightAddon {
  /* position: relative; */
  display: flex;
}

#patientComponent {
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

/* style glyph */
.rightAddon i {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  pointer-events: none;
}

#searchPatientDialer::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-size: x-small;
}

#searchPatientDialer:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: x-small;
}

#searchPatientDialer::-ms-input-placeholder {
  /* Microsoft Edge */
  font-size: x-small;
}

#telemedicineDialer table {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
}

#dialPad td {
  font-size: x-large;
  text-align: center;
  color: rgb(146, 146, 146);
  font-weight: lighter;
}

#dialPad td:hover {
  cursor: pointer;
  background-color: #1699c5;
  color: white;
}

#dialPadButton {
  background-color: #1699c5;
  width: 60%;
  color: white;
  border-radius: 32px 32px 32px 32px;
}

#dialPadButton:focus {
  outline: none;
}

#dialPadButton:hover {
  background-color: #116e8d;
}

#joinButton {
  background-color: #1699c5;
  color: white;
  width: 80%;
  border-radius: 32px 32px 32px 32px;
  font-size: x-small;
}

#joinButton:focus {
  outline: none;
}

#joinButton:hover {
  background-color: #116e8d;
}

#telemedicineDialer::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 10px;
}

#telemedicineDialer::-webkit-scrollbar {
  width: 2px;
  background-color: #f5f5f5;
}

#telemedicineDialer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #dddddd;
}
