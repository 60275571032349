#main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

#paymentCards {
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 768px) {
    /* For medium devices: */

    #main {
        width: 100%;
    }

    #paymentCards {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

  }