.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize !important;
}

.touchable:hover {
    cursor: pointer;
}

.touchableSelected {
    background-color: rgb(22, 154, 197, 0.7) !important;
    color: rgb(252, 252, 252);
}

#listAllPatient {
    box-shadow: 0 1px 2px 0 #0000001a, 0 10px 10px 0 rgba(68, 68, 68, 0.19) !important;
    background-color: white;
    height: 88vh;
    position: relative;
}

::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(242 242 242);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #b7b5b5;
    -webkit-box-shadow: inset 0 0 6px rgba(184, 181, 181, 0.7);
}

#disabledIfCallTrue {
    pointer-events: none !important;
    opacity: 0.6;

}

.customFilterButton {
    /* Blue background */
    background-color: #1699C5;
    /* Remove borders */
    border: none;
    /* White text */
    color: white;
    /* Some padding */
    padding: 6px 16px;
    /* Set a font size */
    font-size: 16px;
    /* Pointer Cursor*/
    cursor: pointer;
    /* Fixed The Position */
    position: absolute;
    right: 0

}

/* Darker background on mouse-over */
.customFilterButton:hover {
    background-color: dodgerblue;
}

.customFilterButton:focus {
    outline: none;
}