#telemedicineDialer {
  flex: 1;
}

/* enable absolute positioning */
.rightAddon {
  position: relative;
}

/* style glyph */
.rightAddon img {
  position: absolute;
  top: 0;
  right: 0;
  padding: 12px;
  cursor: pointer;
}

#inputDialer::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-size: small;
}

#inputDialer:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: small;
}

#inputDialer::-ms-input-placeholder {
  /* Microsoft Edge */
  font-size: small;
}

#dialPad td {
  font-size: large;
  text-align: center;
  color: rgb(146, 146, 146);
  font-weight: lighter;
  padding: 10px 12px;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

#dialPad td:hover {
  cursor: pointer;
  background-color: #1699c5;
  color: white;
}

#dialPadButton {
  background-color: #1699c5;
  width: 100%;
  color: white;
  border-radius: 32px 32px 32px 32px;
}

#dialPadBtnContainer > td {
  padding: 8px 25px;
}

#dialPadButton:focus {
  outline: none;
}

#dialPadButton:hover {
  background-color: #116e8d;
}
