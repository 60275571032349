/* #telemedicineVideoWindowFull {
  background-color: white;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

#telemedicineVideoWindow {
  box-shadow: 0px 0px 2px 0 #0000001a, 0 0px 10px 1px rgba(139, 139, 139, 0.19) !important;
  background-color: white;
  border-radius: 8px;
  height: 90%;
  width: 73%;
  margin-left: 20px;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
} */

.myMainVidepWindow {
  /* transform: scale(-1,1) !important; */
}
.myMainVidepWindow ::before {
  /* transform: scale(-1,1) !important; */
}
.myParticipant {
  /* transform: scaleX(-1) !important; */
}
.myParticipant ::before {
  /* transform: scaleX(-1) !important; */
}

#main-modal > .modal-dialog {
  /* Width */
  width: auto !important;
}

#mainPreviewWindow {
  position: relative;
}

#screenpreview {
  /* -webkit-transform: scaleX(-1); */
  /* transform: scaleX(-1); */
}
/*  
.modal-backdrop {
  display: none;
} */
/* .modal-open .modal {
  margin: 0 auto;
  width: 35%;
} */
#controls {
  position: absolute;
  top: 85%;
  left: 0;
  display: flex;
  width: 100%;
  justify-content: space-around;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}

#mainPreviewWindow:hover > #controls {
  opacity: 1;
}

#telemedicineVideoWindow > #main {
  width: 100%;
  height: 100%;
}

div.container-fluid {
  height: 100%;
}

div#participants {
  overflow-y: auto;
}

div.participant {
  background: center no-repeat
    url("data:image/svg+xml;utf8,<svg class='bi bi-person-fill' width='80' height='45' viewBox='0 0 16 16' fill='currentColor' style='color : white;' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 100-6 3 3 0 000 6z' clip-rule='evenodd'/></svg>");
  border: 1px solid #c73838;
  color: white;
  display: inline-flex;
  height: 90px;
  margin: 10px 5px;
  max-width: 160px;
  overflow: hidden;
}

div.participant > video {
  width: 100%;
}

div.participant.main {
  height: inherit;
  /* margin: 10px 0; */
  width: 100%;
  max-width: 100%;
}

div.participant.main > video {
  min-width: 100%;
  /* width: 720px; */
  height: 520px;
}

div.participant.active {
  border: 1px solid #c73838;
  box-shadow: 0 0 5px #c73838;
}

div.participant.active.pinned {
  border: 1px solid limegreen;
  box-shadow: 0 0 5px limegreen;
}

div.participant::before {
  background-color: #c73838;
  color: white !important;
  content: attr(data-identity);
  font-size: 10px;
  padding: 0 5px;
  position: absolute;
  z-index: 1000;
  max-width: 160px;
  overflow: hidden;
}

div.participant.main::before {
  font-size: 14px;
  padding: 0 10px;
}

@media (max-width: 576px) {
  div#participants {
    overflow-x: auto;
    white-space: nowrap;
  }

  div.participant.main > video {
    height: 180px;
  }
}

@media (max-width: 768px) {
  div.participant.main > video {
    height: 270px;
  }
}

/* .modal-content.ui-resizable {
  overflow-y: scroll;
} */
