#NotificationDropDown {
  border: none !important;
  box-shadow: 0 2px 6px 0 #0000001a, 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  width: 330px;
  margin-right: -135px;
  max-height: 200px;
  min-height: 50px;
  overflow: hidden;
}

#NotificationDropDown:hover {
  overflow: auto;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.menuArrow {
  top: -18px;
  left: 48%;
  width: 0;
  height: 0;
  position: relative;
}
.menuArrow:before,
.menuArrow:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-width: 7px 8px;
  border-style: solid;
  border-color: transparent;
  z-index: 1001;
}
.menuArrow:after {
  bottom: -18px;
  right: -8px;
  border-bottom-color: #fff;
}
.menuArrow:before {
  bottom: -17px;
  right: -8px;
  border-bottom-color: rgba(0, 0, 0, 0.15);
}

#unreadNote p {
  font-weight: bold;
}

#unreadNote:hover {
  cursor: pointer;
}

#readNote p {
  font-weight: normal;
}

#readNote:hover {
  cursor: pointer;
}

#topBarBtnSmall {
  border: 1px solid transparent;
  background-color: transparent;
  padding: 10px;
  border-radius: 32px;
}

#topBarBtnSmall:hover {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  background-color: white;
}

#topBarBtnSmall:focus {
  outline: none;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  background-color: white;
}

#badge {
  position: absolute;
  top: 5px;
  left: 6px;
  font-size: x-small;
  padding: 1px 5px;
  border-radius: 50%;
  background: red;
  color: white;
}

#NotificationDropDown::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 10px;
}

#NotificationDropDown::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}

#NotificationDropDown::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgb(224, 224, 224);
}

@media only screen and (max-width: 768px) {
  #NotificationDropDown {
    overflow: auto;
  }
}
