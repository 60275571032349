#practiceContainer {
  width: 40vw;
  margin-top: 3%;
  background-color: white;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08), 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}

#head {
  background-color: transparent;
  border-bottom: 1px solid grey;
}

#headTextContainer {
  padding: 5%;
}

#headText {
  font-weight: 550;
  color: black;
  margin: 0;
}

#headButton {
  font-size: small;
  border-radius: 32px 32px 32px 32px;
  padding: 1.5% 2% 2% 2%;
  width: 20%;
  margin: 0 5px;
  min-width: 100px;
}

#body {
  font-size: 16px;
  font-family: 'Arial', sans-serif;
  color: rgb(175, 172, 172);
}

.customCol0 {
  width: 5% !important;
  padding: 0 !important;
}

.customCol1 {
  font-weight: 600;
  color: black;
  width: 20%;
}

.customCol2 {
  width: 38%;
  font-size: 16px;
  font-weight: 500;
}

@media only screen and (max-width: 768px) {
  /* For medium devices: */
  #practiceContainer {
    width: 100%;
  }

  #headTextContainer {
    padding: 12% 4% 12% 8%;
  }

  .customCol0 {
    width: 4% !important;
    padding: 0 !important;
  }
}
