/* pending state */
.pending {
  color: #007aff;
  animation-name: pendingState;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  opacity: 0.9;
}

@keyframes pendingState {
  0% {
    opacity: 0.4;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 0.9;
  }
}

#fullModalImage {
  width: 100%;
}
#fullModalImageContainer {
  display: flex;
}

#taskImageContainer,
#taskAudContainer,
#taskVidContainer,
#taskAppContainer {
  width: 100% !important;
  background-color: #1699c5 !important;
  border-radius: 5px !important;
  padding: 3px;
}
#taskImage {
  width: 100%;
  display: flex;
}
