.messageList {
  height: 88vh;
  background-color: white;
}

.viewMessagesList {
  background-color: white;
  height: 51vh;
}

.pending {
  color: #007aff;
  animation-name: pendingState;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  opacity: 0.9;
}

@keyframes pendingState {
  0% {
    opacity: 0.4;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 0.9;
  }
}

#cardUserImage {
  /* background-color: rgb(245, 245, 245); */
  margin: 2%;
  padding: 5px;
  margin-left: 66%;
  width: 34%;
  border-radius: 5px;
}

#cardUserImageWithType {
  margin: 1px 0px;
  border-radius: 5px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

#cardUserImageWithType img {
  width: 100%;
  height: 100px;
  font-weight: 500;
  border: 2px solid #f4f4f8;
  object-fit: cover;
}

#cardUserImage img {
  width: 100%;
  height: 100px;
  font-weight: 500;
  border: 2px solid #007aff;
  object-fit: cover;
}

@media only screen and (max-width: 2200px) {
  #cardUserImage img,
  #cardMainMDimage,
  .imagePending,
  .pendingVideoTag {
    height: 260px;
  }
}

@media only screen and (max-width: 1800px) {
  #cardUserImage img,
  #cardMainMDimage,
  .imagePending,
  .pendingVideotag {
    height: 240px;
  }
}

@media only screen and (max-width: 1560px) {
  #cardUserImage img,
  #cardMainMDimage,
  .imagePending,
  .pendingVideoTag {
    height: 240px;
  }
}

@media only screen and (max-width: 1350px) {
  #cardUserImage img,
  #cardMainMDimage,
  .imagePending,
  .pendingVideoTag {
    height: 240px;
  }
}

@media only screen and (max-width: 1200px) {
  #cardUserImage img,
  #cardMainMDimage,
  .imagePending,
  .pendingVideoTag {
    height: 240px;
  }
}

@media only screen and (max-width: 992px) {
  #cardUserImage img,
  .imagePending,
  .pendingVideoTag {
    height: 240px;
  }
}

@media only screen and (max-width: 768px) {
  #cardUserImage img,
  .imagePending,
  .pendingVideoTag {
    height: 240px;
  }
}

.cardUserVideo {
  width: 100%;
  margin: 2%;
  border-radius: 5px;
  margin-left: 60%;
  padding: 5px;
  /* background-color: rgb(245, 245, 245); */
}

.cardUserVideo video {
  width: 40%;
  height: 400px;
  border: 3px solid rgb(22, 153, 197);
}

.patientAudio {
  border-radius: 5px;
  width: 34%;
  margin-left: 66%;
  margin-top: 20px;
  padding: 5px;
  background-color: #007aff;
}

.patientAudio audio {
  width: 100%;
}
